

/**
 * Docs: Theme switcher
 */
 .switcher {
  position: fixed;
  right: calc(var(--spacing) / 2 + var(--scrollbar-width, 0px));
  bottom: var(--spacing);
  width: auto;
  margin-bottom: 0;
  padding: 0.75rem;
  border-radius: 2rem;
  box-shadow: var(--card-box-shadow);
  line-height: 1;
  text-align: right;
}
.switcher::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.15rem solid currentColor;
  border-radius: 50%;
  background: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%);
  content: "";
  vertical-align: bottom;
  transition: transform var(--transition);
}
.switcher i {
  display: inline-block;
  max-width: 0;
  padding: 0;
  overflow: hidden;
  font-style: normal;
  font-size: 0.875rem;
  white-space: nowrap;
}
.switcher:hover, .switcher:focus {
  max-width: 100%;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}
.switcher:hover::after {
  transform: rotate(180deg);
}
.switcher:hover i {
  max-width: 100%;
  padding: 0 calc(var(--spacing) / 2) 0 calc(var(--spacing) / 4);
  transition: max-width var(--transition), padding var(--transition);
}
.switcher:focus {
  box-shadow: var(--card-box-shadow), 0 0 0 0.2rem var(--secondary-focus);
}
@media (min-width: 576px) {
  .switcher {
    right: calc(var(--spacing) + var(--scrollbar-width, 0px));
  }
}

.staging-btn {
  width: 140px; 
  height: 45px; 
  position: fixed;
  bottom: 0; 
  left: 0; 
  opacity: 0;
  display: flex;
  padding: 10px;
  text-align: center;
  font-weight: 700;
}

.staging-btn:hover{
  transition: all 0.3s ease-in-out;
  transition-delay: 3s;
  background-color: var(--contrast);
  opacity: 1;
}

.staging-btn div{
  position: fixed;
  bottom: -50px;
  left: 40;
  width: 120px;
}

.staging-btn:hover div{
  transition: all 5s ease-in-out;
  bottom: 10px;
}